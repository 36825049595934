import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero/Hero';
import About from '../components/About/About';
import Footer from '../components/Footer/Footer';
import { headData, heroData, aboutData, footerData } from '../data/data';
import { PortfolioProvider } from '../context/context';
import '../style/text-page.scss';

export default () => {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setFooter({ ...footerData });
  }, []);

  const { title, lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>作曲者について | {title}</title>
        <html lang={lang || 'en'} />
        <meta
          name="description"
          content="Yuki Shindoは作曲家として楽曲提供や音楽のプロデュースを行ったり、自身でも複数の名義で音楽を発表しています。直近のキャリアとしてはソフトウェア・エンジニアとして働いており、音楽制作は自由気ままに行っていましたが、動画制作が盛り上がっている昨今、動画制作などに自由に使える効果音や音楽がもっとあってもいいのではないかと思い、このページを作成しました。"
        />
      </Helmet>

      <PortfolioProvider value={{ hero, about, footer }}>
        <Hero />
        <About />
        <Footer />
      </PortfolioProvider>
    </>
  );
};
