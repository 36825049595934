import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about, footer } = useContext(PortfolioContext);
  const { img, resume } = about;
  const { networks } = footer;

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <div className="about-wrapper__image">
              <AboutImg alt="profile image" filename={img} />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="about-wrapper__info">
              <p className="about-wrapper__info-text" style={{ fontSize: 24 }}>
                Yuki Shindo
              </p>
              <p className="about-wrapper__info-text">作曲家・ソフトウェア開発者</p>
              <p className="about-wrapper__info-text">
                作曲家として楽曲提供や音楽のプロデュースを行ったり、自身でも複数の名義で音楽を発表しています。
              </p>
              <p className="about-wrapper__info-text">
                直近のキャリアとしてはソフトウェア・エンジニアとして働いており、音楽制作は自由気ままに行っていましたが、動画制作が盛り上がっている昨今、動画制作などに自由に使える効果音や音楽がもっとあってもいいのではないかと思い、このページを作成しました。
              </p>
              {resume && (
                <span className="d-flex mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href={resume}
                  >
                    プロフィール
                  </a>
                </span>
              )}
              <div className="social-links">
                {networks &&
                  networks.map((network) => {
                    const { id, name, url } = network;

                    if (!id || !name || !url) return null;

                    return (
                      <a
                        key={id}
                        href={url}
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label={name}
                      >
                        <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                      </a>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
